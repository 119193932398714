import { colors } from 'enums'
import { CSSObjectWithLabel, ControlProps } from 'react-select'
import { TCalculatorAction } from 'store/calculator/types'
import styled from 'styled-components'

export const AnswerWrap = styled.ul`
   display: flex;
   list-style: none;
   flex-direction: column;
   gap: 30px;
`
export const AnswerItem = styled.li`
   display: flex;

   width: 100%;
`

export const InputsWrap = styled.div`
   display: flex;
   width: 100%;
   gap: 15px;
   @media (min-width: 1366px) {
      gap: 30px;
   }

   align-items: center;
   position: relative;
`

export const SelectWrap = styled.div`
   display: flex;
   position: relative;
   flex-direction: column;
   p {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
   }
`

export const selectStyles = {
   control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<{ label: string; value: TCalculatorAction }>
   ) => ({
      ...provided,
      backgroundColor: 'transparent',
      width: '316px',
      height: '44px',
      borderRadius: '8px',
      boxShadow: 'none',
      border:
         state.isFocused || state.menuIsOpen
            ? `1px solid ${colors.black}`
            : `1px solid ${colors.lighter_grey}`,
      '&:hover': {
         border: state.isFocused || state.menuIsOpen ? '1px solid #222222' : undefined
      }
   }),
   dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided
      // display: 'none'
   }),
   indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none'
   })
}

export const ProductButton = styled.button<{ $isAllproducts: boolean }>`
   border: none;
   outline: none;
   border-radius: 8px;
   color: ${({ $isAllproducts }) => (!$isAllproducts ? '#ffffff' : colors.blue)};
   background-color: ${({ $isAllproducts }) =>
      $isAllproducts ? 'transparent' : colors.blue};
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   height: 44px;

   min-width: 275px;
   max-width: 330px;
   margin-top: 22px;
   font-size: 14px;
   font-weight: 600;
   svg {
      width: 25px;
      height: 25px;
      margin-right: 6px;
   }
   &[disabled] {
      background-color: ${colors.grey};
      cursor: default;
   }
`
export const InputButtons = styled.button`
   position: absolute;
   border: none;
   outline: none;
   background-color: transparent;
   top: 0;
   left: 200px;
   z-index: 1;
   color: ${colors.red};
   cursor: pointer;
   display: flex;
   align-items: center;
   gap: 4px;
   &[disabled] {
      color: ${colors.grey};
   }
`
export const AnswersContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 90%;
   gap: 15px;
`

export const ProductButtons = styled.div``
