import styled from 'styled-components'
import { colors } from 'enums'
import { CSSObjectWithLabel, ControlProps } from 'react-select'
export const QeuestionsList = styled.ul`
   display: flex;
   list-style: none;
   flex-direction: column;
   gap: 30px;
   padding: 10px 20px 20px;
`

export const QuestionItem = styled.li<{ $deep: number }>`
   display: flex;
   gap: 30px;
   width: ${({ $deep }) => `calc(100% - ${$deep * 26}px)`};
   flex-direction: column;
   padding: 28px 20px;
   background: ${colors.light_grey};
   border-radius: 12px;
   margin-left: auto;
   position: relative;
`

export const ParrentQuestionWrap = styled.div`
   height: 60px;
   width: 100%;
   overflow: hidden;
   display: flex;
   align-items: center;
   background-color: #e5e9f3;
   border-radius: 5px;
   border-left: 5px solid red;
   border-color: ${colors.blue};
   padding: 0 18px;
   span {
      margin-left: 13px;
      display: flex;
      align-items: baseline;
      flex-direction: column;
      gap: 4px;
      p {
         &:last-child {
            font-weight: 600;
         }
      }
   }
`
