import { colors } from 'enums'
import styled from 'styled-components'

export const QurstionItemStyled = styled.li`
   padding: 0 20px;
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: space-between;
   height: 74px;

   &:not(:last-child) {
      border-bottom: 1px solid ${colors.border_grey_color};
   }
   div {
      width: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: flex-start;
      &:nth-child(1) {
         padding: 0 10px 0 0;
         max-width: 200px;
      }
      &:nth-child(2) {
         max-width: 300px;

         p {
            align-items: center;
            height: 68px;
            display: flex;
            max-width: 100%;
            overflow: auto;
            padding: 2px 0;
         }
      }
      &:nth-child(3) {
         justify-content: flex-start;
         max-width: 430px;
         flex-wrap: wrap;
         gap: 5px;
      }
      &:nth-child(4) {
         gap: 20px;
         justify-content: flex-end;
         max-width: 120px;
         padding: 0 0 0 10px;
      }
   }
`
export const DeleteBtn = styled.button`
   background-color: ${colors.red};
   width: 36px;
   height: 36px;
   color: #ffffff;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 8px;
   transition: background-color 0.3s;
   cursor: pointer;
   border: none;

   &:hover {
      background-color: #d67878;
   }
   svg {
      width: 24px;
      height: 24px;
   }
`
export const ModalWrap = styled.div`
   position: absolute;
   display: flex;
   top: 50%;
   left: 50%;
   width: 480px;
   gap: 30px;
   /* height: 300px; */
   transform: translate(-50%, -50%);
   background-color: ${colors.solid_white};
   border-radius: 12px;
   padding: 20px;
   flex-direction: column;
`
export const ButtonWrap = styled.div`
   width: 100%;
   display: flex;
   gap: 20px;
   justify-content: center;
`
export const ButtonDelete = styled.button`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: ${colors.red};
   border: none;
   text-decoration: none;
   cursor: pointer;
   font-size: 16px;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   color: ${colors.solid_white};
   transition: background-color 0.3s;
   &:hover {
      background-color: #d67878;
   }
   &[disabled] {
      background-color: ${colors.grey};
   }
`

export const CloseBtn = styled.button`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: transparent;
   border: 1px solid ${colors.grey};
   text-decoration: none;
   cursor: pointer;
   font-size: 16px;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   color: ${colors.grey};
   transition: background-color 0.3s;
   &:hover {
      background-color: ${colors.light_grey};
   }
`
export const HeadingWrap = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   svg {
      cursor: pointer;
      width: 25px;
      height: 25px;
   }
`

export const TextModal = styled.p`
   font-size: 16px;
`
