import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   Pagination,
   SubPagesBar,
   TColumnTable,
   TDataTable,
   TSection,
   TSort
} from 'components'
import { api } from 'config'
import { colors, ELocales } from 'enums'
import { useLocalization, useTypedSelector } from 'hooks'
import { DragAndDropProduct } from 'pages/Catalog/components'
import { PAGE_LIMIT } from 'pages/Catalog/consts'
import { Image, StyledText } from 'pages/Catalog/styled'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { categoryActions, getMainCategorySelector } from 'store'
import { TCategory, TMeta } from 'store/category/types'
import { TForm } from './types'

export const CategoriesIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const { categories, loading, ids } = useTypedSelector(getMainCategorySelector)
   const navigate = useNavigate()
   const [page, setPage] = useState<number>(0)
   // const [locale] = useLocalization()
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

   const [form, setForm] = useState<TForm>({
      name: '',
      photo: null,
      _id: '',
      parent: '',
      metaDescription: '',
      metaKeywords: []
   })

   const [searchParams, setSearchParams] = useSearchParams({})
   const [categoriesDnd, setCategoriesDnd] = useState<TCategory[][]>([])

   const filteredCategoriesDnd = useMemo(() => {
      return [
         categoriesDnd.flat().filter((value, index, self) => {
            return (
               self.findIndex((v) => v._id === value._id) === index &&
               value.parent == null
            )
         })
      ]
   }, [categoriesDnd])

   console.log('filteredCategoriesDnd', filteredCategoriesDnd)

   const viewMoreCategories = useMemo(() => {
      if (categories.meta)
         return categories.meta?.totalCount > filteredCategoriesDnd.flat().length
      return false
   }, [filteredCategoriesDnd])

   const Requests = {
      getCategories: () => {
         dispatch(
            categoryActions.getCategories({
               field: 'parent',
               value: 'null',
               limit: PAGE_LIMIT,
               page: page,
               lang: ELocales.ru,
               hidden: 'false',
               sortBy: 'order',
               order: 1
            })
         )
      },
      editCategories: () => {
         dispatch(
            categoryActions.editCategoryOrder({
               data: filteredCategoriesDnd.map((categories) =>
                  categories.map((category, index) => ({
                     _id: category._id,
                     data: {
                        order: index + 1
                     }
                  }))
               )[0]
            })
         )
      }
   }

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         // setSortParamsForm({ sortBy, order })
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      paginateDropDownChangeHandler: (value: string) => {
         setForm((form) => ({ ...form, parent: value }))
      },
      openEditButtonClickHandler: (e: SyntheticEvent, index: number) => {
         navigate(
            `/catalog/categories/${
               categories.data.filter((item) => item.show)[index]._id as string
            }`
         )
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setForm({ ...form, [input.name]: input.value })
      },
      onDragEnd: (products?: any[], result?: any, index?: number) => {
         const { source, destination } = result

         // dropped outside the list
         if (!destination) {
            return
         }
         const sInd = +source.droppableId
         const dInd = +destination.droppableId

         if (sInd === dInd) {
            const items = Utils.reorder(
               filteredCategoriesDnd[sInd],
               source.index,
               destination.index
            )
            const newState = [...filteredCategoriesDnd]
            newState[sInd] = items as TCategory[]
            setCategoriesDnd(newState)
         } else {
            const result = Utils.move(
               filteredCategoriesDnd[sInd],
               filteredCategoriesDnd[dInd],
               source,
               destination
            )
            const newState = [...filteredCategoriesDnd]
            newState[sInd] = result.sourceClone as TCategory[]
            newState[dInd] = result.destClone as TCategory[]
            setCategoriesDnd(newState)
         }
      },
      saveButtonClickHadler: () => {
         Requests.editCategories()
      },
      viewAdditional: () => {
         if (viewMoreCategories) setPage(page + 1)
         Requests.getCategories()
         if (viewMoreCategories)
            setSearchParams({ ...searchParams, page: '' + (page + 1) })
      }
   }

   const Utils = {
      reorder: (row: TCategory[], startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      },
      move: (
         source: TCategory[],
         destination: TCategory[],
         droppableSource: { index: number; droppableId: string },
         droppableDestination: { droppableId: string; index: number }
      ) => {
         const sourceClone = Array.from(source)
         const destClone = Array.from(destination)
         const [removed] = sourceClone.splice(droppableSource.index, 1)

         destClone.splice(droppableDestination.index, 0, removed)

         return { sourceClone, destClone }
      }
   }

   const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: `0`,
      background: colors.solid_white,

      // change background colour if dragging

      // styles we need to apply on draggables
      ...draggableStyle
   })

   const getListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      width: '100%',
      // alignItems: 'center',
      flexDirection: 'column',
      padding: `8px 8px 8px 20px`,
      overflow: 'auto'
      // flex: 1
   })

   const paginationSections: TSection[] = useMemo(
      () => [
         {
            title: t('current'),
            active: true
         },
         {
            title: t('new.categories'),
            onClickHandler: () => {
               navigate('/catalog/newCategories')
            }
         }
      ],
      []
   )

   useEffect(() => {
      if (ids.length > 0) {
         dispatch(categoryActions.setIds([]))
      }
   }, [])

   useEffect(() => {
      Requests.getCategories()
      setSearchParams({ ...searchParams, page: '' + (page + 1) })
   }, [page])

   useEffect(() => {
      categories &&
         categories.data.length > 0 &&
         setCategoriesDnd(
            categoriesDnd.concat([
               categories.data.slice().sort((a, b) => a.order - b.order)
            ])
         )
   }, [categories])

   return (
      <AdminPanelContainer
         loading={loading}
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.saveButtonClickHadler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t('categories.and.subcategories')}
            />
         }>
         <SubPagesBar sections={paginationSections} />

         <FlexContainer justify="space-between" padding="8px" width="100%" wrap="nowrap">
            <div>
               <StyledText style={{ width: '120px' }}>{t('photo')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '120px' }}>{t('icon')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('title')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('total.product')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('subcategories')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('visability')}</StyledText>
            </div>
         </FlexContainer>

         {categories.data.length > 0 && (
            <FlexContainer align="center" gap="30px" wrap="nowrap" direction="column">
               <DragDropContext
                  onDragEnd={(result) => Events.onDragEnd(filteredCategoriesDnd, result)}>
                  {filteredCategoriesDnd.map((item, ind) => (
                     <Droppable droppableId={`${ind}`} direction="vertical" key={ind}>
                        {(provided: any, snapshot: any) => (
                           <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}>
                              {provided.placeholder}
                              {item.map((product: TCategory, index: number) => (
                                 <Draggable
                                    draggableId={item[index]._id}
                                    index={index}
                                    key={product._id}>
                                    {(provided, snapshot) => (
                                       <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                             snapshot.isDragging,
                                             provided.draggableProps.style,
                                             8
                                          )}>
                                          <DragAndDropProduct
                                             key={product._id}
                                             productItem={product}
                                             path="categories"
                                          />
                                       </div>
                                    )}
                                 </Draggable>
                              ))}
                           </div>
                        )}
                     </Droppable>
                  ))}
               </DragDropContext>
            </FlexContainer>
         )}
         {viewMoreCategories && (
            <FlexContainer justify="center">
               <Button onClick={Events.viewAdditional}>{t('view.another')}</Button>
            </FlexContainer>
         )}
      </AdminPanelContainer>
   )
}
