import { TCalculatorQuestion, TGetCalculatorQuestions } from 'store/calculator/types'
import { EditButton } from '..'
import { getMainCategorySelector, ApiCalculatorService } from 'store'
import { useTypedSelector } from 'hooks'
import { useTranslation } from 'react-i18next'
import {
   ButtonDelete,
   ButtonWrap,
   CloseBtn,
   DeleteBtn,
   HeadingWrap,
   ModalWrap,
   QurstionItemStyled,
   TextModal
} from './styled'
import { AxiosResponse } from 'axios'
import { KeyedMutator } from 'swr'
import { MdDeleteForever } from 'react-icons/md'
import { ModalMaterial } from 'components'
import { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { toast } from 'react-toastify'

export const QuestionItem = ({
   item,
   mutate
}: {
   item: TCalculatorQuestion
   mutate: KeyedMutator<AxiosResponse<TGetCalculatorQuestions>>
}) => {
   const [open, setOpen] = useState(false)
   const { accessToken } = useTypedSelector((state) => state.user)
   const closeModal = () => setOpen(false)

   const onDeleteQuestionClick = async () => {
      try {
         await ApiCalculatorService.deleteCalculator(item._id, accessToken || '')
         mutate()
         closeModal()
         toast.info(t('successfullyDeleted'))
      } catch (error) {
         toast.error(t('anErrorHasOccurred'))
      }
   }
   const { t, i18n } = useTranslation()
   const lang = i18n.language
   const { categories } = useTypedSelector(getMainCategorySelector)

   return (
      <QurstionItemStyled>
         <ModalMaterial isOpen={open} close={closeModal}>
            <ModalWrap>
               <HeadingWrap>
                  <h2>{t('deleteQuestion')}</h2>
                  <IoClose onClick={closeModal} />
               </HeadingWrap>
               <TextModal>{t('yourWantToDelete')}</TextModal>
               <ButtonWrap>
                  <CloseBtn onClick={closeModal}>{t('close')}</CloseBtn>
                  <ButtonDelete onClick={onDeleteQuestionClick}>
                     {t('delete')}
                  </ButtonDelete>
               </ButtonWrap>
            </ModalWrap>
         </ModalMaterial>
         <div>{categories?.data?.find((el) => el?._id === item?.category)?.title}</div>
         <div>
            <p>{item.title.find((el) => el.lang === lang)?.value}</p>
         </div>
         <div>
            {item.rooms.map((room, i) => (
               <p key={room + i}>{t(`${room}`)}</p>
            ))}
         </div>
         <div>
            <DeleteBtn onClick={() => setOpen(true)}>
               <MdDeleteForever />
            </DeleteBtn>
            <EditButton link={`/calculator/${item._id}`} />
         </div>
      </QurstionItemStyled>
   )
}
