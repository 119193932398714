import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { CATEGORY_URL } from './config'
import {
   TChangeProductOrderRequestPayload,
   TCreateCategoryRequestPayload,
   TEditCategoryOrderPayloadRequest,
   TEditCategoryRequestPaylaod,
   TGetCategoriesRequestPayload,
   TGetCategoryRequestPayload,
   TGetProductsByCategoryRequestPayload,
   TRemoveCategoryRequestPayload
} from './types'

export class ApiCategoryService extends HttpService {
   static getCategories({
      token,
      limit = 10,
      page = 0,
      order = '',
      sortBy = '',
      lang = ELocales.ru,
      value,
      field,
      ...params
   }: TGetCategoriesRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: CATEGORY_URL.getCategories,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            limit,
            skip: limit * page,
            order,
            sortBy,
            lang,
            value,
            field,
            ...params
         }
      })

      return response
   }

   static removeCategory({
      token,
      _id
   }: TRemoveCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.removeCategory}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static editCategory({
      token,
      _id,
      data
   }: TEditCategoryRequestPaylaod): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.editCategory}/${_id}`,
         method: 'PATCH',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         data
      })

      return response
   }

   static createCategory({
      token,
      data
   }: TCreateCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: CATEGORY_URL.createCategory,
         method: 'POST',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         data
      })

      return response
   }

   static getCategory({
      token,
      _id,
      lang = ELocales.ru,
      ...params
   }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getCategory}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            ...params,
            lang
         }
      })

      return response
   }

   static getCategoryParent({
      token,
      _id,
      lang = ELocales.ru
   }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getCategoryParent}/${_id}/branch`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            lang
         }
      })

      return response
   }

   static getCategoriesByParent({
      token,
      _id,
      lang = ELocales.ru,
      limit,
      page,
      sortBy,
      order
   }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getCategoryParent}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            field: 'parent',
            value: _id,
            lang,
            skip: (page as number) * (limit as number),
            sortBy,
            order,
            limit: limit
         }
      })

      return response
   }

   static getProductsByCategory({
      token,
      _id,
      lang = ELocales.ru
   }: TGetProductsByCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getProductsByCategory(_id)}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            lang
         }
      })

      return response
   }

   static editCategoryBulk({
      token,
      data
   }: TEditCategoryOrderPayloadRequest): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.editCategoryOrder}`,
         method: 'PATCH',
         data: data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static changeProductOrder({ token, data }: TChangeProductOrderRequestPayload) {
      return this.request({
         url: `${CATEGORY_URL.changeProductOrder}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
   }
}
