import { AdminPanelContainer, Pagination } from 'components'
import { Header, FilterBar, EditButton, QuestionItem } from './components'
import { categoryActions, getMainCategorySelector, useGetCalculators } from 'store'
import { useTypedSelector } from 'hooks'
import { FirsLevelWrap, QuestionList } from './styled'
import { useTranslation } from 'react-i18next'
import { NavButton } from './components/Header/styled'
import { FiPlus } from 'react-icons/fi'

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const LIMIT = 20

export const Calculator = () => {
   const [page, setPage] = useState(1)
   const { order, sortBy } = useTypedSelector((state) => state.calculator)
   const params = {
      limit: LIMIT,
      skip: (page - 1) * LIMIT,
      order,
      sortBy: sortBy || undefined,
      isParent: true
   }
   const { t } = useTranslation()

   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(
         categoryActions.getCategories({ field: 'productsTotalCount', limit: 1000 })
      )
   }, [])

   const { questions, mutate } = useGetCalculators(params)

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={<h2>{t('calculatorQuestions')}</h2>}
               button={
                  <NavButton to="/calculator/createCalculator">
                     <FiPlus /> {t('create')}
                  </NavButton>
               }
            />
         }>
         <FilterBar />
         <FirsLevelWrap>
            <p>{t('firstStage')}</p>
            <EditButton link="/calculator/first-stage" />
         </FirsLevelWrap>
         <QuestionList>
            {questions?.data?.data?.map((item) => (
               <QuestionItem key={item._id} item={item} mutate={mutate} />
            ))}
         </QuestionList>
         {questions?.data.meta && +questions?.data?.meta?.totalCount / LIMIT > 1 && (
            <Pagination
               onPageChange={(page) => setPage(page.selected + 1)}
               page={page - 1}
               pageCount={
                  questions?.data.meta
                     ? Number(
                          (
                             (questions?.data?.meta?.totalCount as number) / LIMIT
                          ).toFixed()
                       )
                     : 1
               }
            />
         )}
      </AdminPanelContainer>
   )
}
