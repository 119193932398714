import {
   TCalculatorQuestion,
   TEditCalculatorBulkType,
   TRooms
} from 'store/calculator/types'
import { TAnswer, TEditCalculatorForm, TQuestion, TSetValueProps } from './types'
import { questionsOptions } from '../CreateCalculator/helpers'
import useSWR from 'swr'
import { ApiCalculatorService, ApiProductService, store } from 'store'
import { useTypedSelector } from 'hooks'
import { useTranslation } from 'react-i18next'
import { ELocales } from 'enums'
import { TProductType } from '../CreateCalculator/components/ModalTieUpProduct/types'
import { t } from 'i18next'
import { i18n } from 'config'

import { TProduct } from 'store/product/types'
import { TItemDragTypes } from './components/ModalTieUpProduct/types'

export const createId = () => {
   let timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
   return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
         .replace(/[x]/g, function () {
            return ((Math.random() * 16) | 0).toString(16)
         })
         .toLowerCase()
   )
}

const allRooms: TRooms[] = [
   'corridor',
   'livingRoom',
   'bathroom',
   'bedroom',
   'other',
   'kitchen'
]

const roomsArray = [
   'wallArea',
   'totalArea',
   'bathroomArea',
   'bathroomWalls',
   'corridorArea',
   'corridorWalls',
   'livingRoomArea',
   'livingRoomWalls',
   'bedroomArea',
   'bedroomWalls',
   'kitchenArea',
   'kitchenWalls',
   'bathroomLength',
   'livingRoomLength',
   'corridorLength',
   'bedroomLength',
   'kitchenLength',
   'bathroomWidth',
   'livingRoomWidth',
   'corridorWidth',
   'bedroomWidth',
   'kitchenWidth',
   'bathroomHeight',
   'livingRoomHeight',
   'corridorHeight',
   'bedroomHeight',
   'kitchenHeight',
   'totalLength',
   'totalWidth',
   'maxHeight'
]
const sinsArray = ['+', '*', 'x', '-', '/']

export const buildExpression = async (elements?: string[]) => {
   const { accessToken } = store.getState().user
   const lang = i18n.language as ELocales
   let arr: TItemDragTypes[] = []

   if (elements && elements?.length) {
      for (const item of elements) {
         if (roomsArray.includes(item)) {
            arr.push({
               label: t(item),
               value: item,
               type: 'block',
               id: createId()
            })
         } else if (sinsArray.includes(item)) {
            item === '*' || item === 'x'
               ? arr.push({ label: 'x', value: '*', type: 'signs', id: createId() })
               : arr.push({
                    label: item,
                    value: item,
                    type: 'signs',
                    id: createId()
                 })
         } else if (item.length < 20 && !isNaN(+item)) {
            arr.push({
               label: t('inputField'),
               value: 'inputField',
               type: 'signs',
               id: createId(),
               inputValue: item
            })
         } else if (item.length > 20) {
            const { data } = await ApiCalculatorService.getCalculatorQuestion(
               item,
               lang,
               accessToken || ''
            )
            arr.push({
               label: data.title.find((l) => l.lang === lang)?.value || '',
               value: 'question',
               type: 'block',
               id: data._id
            })
         }
      }
   }
   return arr
}

function compareRooms(receivedRooms: string[]): Record<TRooms, boolean> {
   const result: Record<TRooms, boolean> = allRooms.reduce((acc, room) => {
      acc[room] = receivedRooms.includes(room)
      return acc
   }, {} as Record<TRooms, boolean>)

   return result
}

const processQuestion = async (
   question: TCalculatorQuestion,
   productsInAnswers?: TProduct[]
) => {
   const titleAz = question.title.find((el) => el.lang === 'az')?.value || ''
   const titleRu = question.title.find((el) => el.lang === 'ru')?.value || ''

   const answersResults: TAnswer[] = []

   for (const answer of question.answers) {
      const answerProductsData =
         answer.products?.map(async (productSet) => {
            const economProduct = productsInAnswers?.find(
               (el) => el._id === productSet.economproduct
            )
            const standardProduct = productsInAnswers?.find(
               (el) => el._id === productSet.standartproduct
            )
            const premiumProduct = productsInAnswers?.find(
               (el) => el._id === productSet.premiumproduct
            )
            const products: { type: TProductType; product: TProduct }[] = [
               { type: 'economproduct', product: economProduct },
               { type: 'standartproduct', product: standardProduct },
               { type: 'premiumproduct', product: premiumProduct }
            ].filter((el): el is { type: TProductType; product: TProduct } =>
               Boolean(el.product)
            )
            const elements = productSet.expression
               ?.match(/\{([^}]+)\}/g)
               ?.map((element) => element.replace(/[{}]/g, ''))
            const expression = elements ? await buildExpression(elements) : undefined

            return { products, expression }
         }) || []

      const answerAz = answer?.answer?.find((el) => el.lang === 'az')?.value || ''
      const answerRu = answer?.answer?.find((el) => el.lang === 'ru')?.value || ''
      const action =
         questionsOptions?.find((el) => el.value === answer.action) || questionsOptions[0]

      answersResults.push({
         answerAz,
         answerRu,
         action,
         id: answer._id,
         question: answer?.question,
         answerProductsData: await Promise.all(answerProductsData)
         // expression: undefined // Це поле може бути застарілим, залежно від вашої бізнес-логіки
      })
   }
   return {
      id: question._id,
      questionAz: titleAz,
      questionRu: titleRu,
      parentId: question.parentId,
      isParent: question.isParent,
      closedQuestion: question.type === 'close',
      answers: answersResults
   }
}

function orderQuestions(questionArray: TQuestion[]): TQuestion[] {
   const orderedQuestions: TQuestion[] = []
   const questionMap = new Map<string, TQuestion>()
   const childrenMap = new Map<string, TQuestion[]>()

   // Підготовка мап для швидкого доступу
   questionArray.forEach((question) => {
      questionMap.set(question.id, question)
      if (!childrenMap.has(question.parentId || '')) {
         childrenMap.set(question.parentId || '', [])
      }
      childrenMap?.get(question.parentId || '')?.push(question)
   })

   // Функція для рекурсивного додавання дочірніх елементів
   const addChildren = (parentId: string) => {
      const children = childrenMap.get(parentId)
      if (children) {
         children.forEach((child) => {
            if (!orderedQuestions.includes(child)) {
               orderedQuestions.push(child)
               addChildren(child.id)
            }
         })
      }
   }

   // Додавання батьківського елемента та його дочірніх елементів
   const parentQuestion = questionArray.find((question) => question.isParent)
   if (parentQuestion) {
      orderedQuestions.push(parentQuestion)
      addChildren(parentQuestion.id)
   }

   // Обхід тих елементів, які ще не були додані
   questionArray.forEach((question) => {
      if (!orderedQuestions.includes(question)) {
         orderedQuestions.push(question)
         addChildren(question.id)
      }
   })

   return orderedQuestions
}

export const setDefaultValue = async ({
   setValue,
   categories,
   currentValue,
   productsInAnswers
}: TSetValueProps) => {
   const category = categories.find((el) => el._id === currentValue.category)
   const childQuestionsResults = []
   category && setValue('nameCategory', { label: category.title, value: category._id })

   if (currentValue.childQuestions) {
      for (const childQuestion of currentValue.childQuestions) {
         const childQuestionResult = await processQuestion(
            childQuestion,
            productsInAnswers
         )
         childQuestionsResults.push(childQuestionResult)
      }
   }
   const mainQuestion = await processQuestion(currentValue, productsInAnswers)

   const questionArray = orderQuestions([mainQuestion, ...childQuestionsResults])

   setValue('typeofrooms', compareRooms(currentValue.rooms))
   setValue(`questions`, questionArray)
}

export const createData = (data: TEditCalculatorForm) => {
   const rooms = Object.entries(data.typeofrooms)
      .filter((item): item is [TRooms, boolean] => Boolean(item[1]))
      .flatMap((item) => item[0])

   for (const question of data.questions) {
      for (const answer of question.answers) {
         const actionsRequiringProducts = ['products', 'question&products']
         if (actionsRequiringProducts.includes(answer.action.value)) {
            if (
               !answer.answerProductsData?.length ||
               answer.answerProductsData.some((el) => el.products?.length !== 3)
            ) {
               return false
            }
         }
      }
   }

   const calculatorData: TEditCalculatorBulkType = data?.questions?.map((item) => ({
      _id: item.id,
      data: {
         _id: item.id,
         title: [
            { lang: 'az', value: item.questionAz },
            { lang: 'ru', value: item.questionRu }
         ],
         type: item.closedQuestion ? 'close' : 'open',
         rooms: rooms,
         answers: item.answers.map((el) => ({
            _id: el.id,
            answer: [
               { lang: 'az', value: el.answerAz },
               { lang: 'ru', value: el.answerRu }
            ],
            action: el.action.value,

            question: el.question || undefined,
            products:
               el.answerProductsData?.map((item) => ({
                  economproduct: item?.products?.find((pr) => pr.type === 'economproduct')
                     ?.product._id,
                  standartproduct: item?.products?.find(
                     (pr) => pr.type === 'standartproduct'
                  )?.product._id,
                  premiumproduct: item?.products?.find(
                     (pr) => pr.type === 'premiumproduct'
                  )?.product._id,
                  expression: item.expression
                     ?.map((exp) => {
                        if (exp.value === 'inputField') {
                           return `{${exp.inputValue}}`
                        } else if (exp.type === 'signs' && exp.value !== 'inputField') {
                           return `{${exp.value}}`
                        } else if (exp.type === 'block' && exp.value !== 'question') {
                           return `{${exp.value}}`
                        } else if (exp.value === 'question' && exp.type === 'block') {
                           return `{${exp.id}}`
                        }
                     })
                     .join(',')
                     .replace(/,/g, '')
               })) || []
         })),
         isParent: item.isParent,
         parentId: item.parentId,
         category: data.nameCategory.value
      }
   }))

   return calculatorData
}

export const useGetProducts = (array?: string[]) => {
   const { accessToken } = useTypedSelector((state) => state.user)
   const { i18n } = useTranslation()
   const lang = i18n.language
   const {
      data: products,
      isLoading,
      error
   } = useSWR(
      array?.length ? ['getProductsByIds', array] : null,
      ([, arr]) =>
         ApiProductService.getProducts({
            limit: 100,
            token: accessToken || '',
            _id: arr,
            lang: lang as ELocales
         }),
      { revalidateOnFocus: false, keepPreviousData: true }
   )
   return { products, isLoading, error }
}
